import React from 'react';
import './App.css';
interface State {
	count: number,
}
class Timer extends React.Component<{}, State> {
	interval: any;
	constructor(props: {}) {
		super(props);
		this.state = { count: 0 };
		this.change = this.change.bind(this);
	}

	change(decrease = false) {
		this.interval = setInterval(() => {
			this.setState((prevState: any) => ({
				count: decrease ? prevState.count - 1 : prevState.count + 1,
			}));
			if (this.state.count === 5 && !decrease) {
				clearInterval(this.interval);
			}
			if (this.state.count === 0 && decrease) {
				clearInterval(this.interval);
			}
		}, 2000);

	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const percentage = this.state.count * 20;
		return (
			<div className="App">
				<div className="buttons">
					<button disabled={this.state.count !== 0} onClick={() => this.change()}>increaseWaterLevel</button>
					<button disabled={this.state.count !== 5} onClick={() => this.change(true)}>decreaseWaterLevel</button>
				</div>
				<div className="bathtub">
					<p>{percentage}%</p>
					<div
						className="water"
						style={{
							height: `${percentage}px`,
						}}
					></div>
				</div>
			</div >
		);
	}
}

export default Timer;
